import {useState, useEffect} from "react";
import axios from "axios";
import {Link} from 'react-router-dom';
import "../style/Footer.css";

function Footer () {
    const [popularGame, setPopularGame] = useState();

    const apiKey = process.env.REACT_APP_API_KEY;

    //Definit les options pour le lien de l'api avec les clefs API
    const options = {
        method: 'GET',
        url: 'https://free-to-play-games-database.p.rapidapi.com/api/games',
        params: {'sort-by': 'popularity'},
        headers: {
          'X-RapidAPI-Key': apiKey,
          'X-RapidAPI-Host': 'free-to-play-games-database.p.rapidapi.com'
        },
      };

    useEffect(() => {
        axios(options)
        .then((response) => response.data)
        .then((data) => setPopularGame(data.slice(0,3))
       )
        },[]);
        
    return (
        <div className="footer" >
            <h1>Popular games</h1>
            {popularGame && popularGame.map((item, index) => 
            <ul  className="footerGame" key={"footer" + index}>
                <li>{item.title}</li>
                <li><img srcSet={item.thumbnail} width="100px" alt={item.title} /></li>
                <Link to={`/games/${item.id}`}><button>More</button></Link>
            </ul>
            )}
        </div>
    )
}

export default Footer;