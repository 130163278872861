import { Link } from 'react-router-dom';
import GameCard from "./GameCard";
import { useParams } from 'react-router';
import "../style/ApiViewer.css";
import NavBar from './NavBar'
const myStyle = {display:"flex",justifyContent: "space-around"}

function Index(props) {
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",];
    let {letter} = useParams();
    let count = 0;
    return (
        <div>
            <NavBar/>
            <ul style={myStyle}>
                {alphabet.map((val,index)=>(
                    <Link to={`/indexgames/${val}`} key={"index" + index}>
                    <p>{val}</p>
                    </Link>
                ))}
            </ul>
            <div className="ApiViewer">
            <ul>
                
                {/* Boucle via map sur la liste pour passer les éléments à l'enfant GameCard */}
                {props.val && letter  && props.val
                .filter((e) => (
                    e.title ? e.title.toLowerCase()[0]=== letter.toLowerCase():"")
                    )
                
                .map((valu, index) => {
                    {count++}
                    return (
                        <li key={index}>
                            <GameCard {...valu} />
                        </li>
                )})
                }
                {props.val && count === 0 ? <p>No Game Found</p> :""}
            </ul>
            </div>
        </div>
    )}
export default Index;
