import React, {useState,useEffect} from "react";
import axios from "axios";
import RandomGame from "./RandomGame";
import SimpleSearch from "./SimpleSearch";
import { RadialMenu } from "./RadialMenu";
import AdvancedSearch from "./AdvancedSearch";
import Footer from '../components/Footer';
import Index from "./IndexGame";
import SelectedGames from "./SelectedGames"
import Quiz from "./Quiz";

function ApiAccess(props) {
    //l'api est stocké dans le state.
    const [apiData, setApiData] = useState([])
    const apiKey = process.env.REACT_APP_API_KEY;

    //Definit les options pour le lien de l'api avec les clefs API
    const options = {
        method: 'GET',
        url: 'https://free-to-play-games-database.p.rapidapi.com/api/games',
        headers: {
          'X-RapidAPI-Key': apiKey,
          'X-RapidAPI-Host': 'free-to-play-games-database.p.rapidapi.com'
        },
      };

      /*useEffect démarre quand le composant est monté et 
        definit le state quand le composant est chargé*/
    useEffect(() => {
        axios(options)
        .then(data => setApiData(data.data))
        //gestion des erreurs
        .catch(error => console.warn('Authorization failed : ' + error.message));
      },[])

    return (
        <div className="apiAccess">
          {props.compo === "random"? <RandomGame val={apiData} key="RandomGame"/>:""}
          {props.compo === "search"? <SimpleSearch val={apiData} key="SimpleSearch"/>:""}
          {props.compo === "home"? <RadialMenu val={apiData} key="Home"/>:""}
          {props.compo === "advanced-search" ? <AdvancedSearch val={apiData} key="AdvancedSearch"/> : ""}
          {props.compo === "index"? <Index val={apiData} key="Index"/>:""}
          {props.compo === "selected"? <SelectedGames val={apiData} key="Home"/>:""}
          {props.compo === "quiz"? <Quiz val={apiData} key="Home"/>:""}
          {props.compo === "footer"? <Footer val={apiData} key="Home"/>:""}
        </div>
    );
}

export default ApiAccess;
