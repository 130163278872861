import "../style/ApiViewer.css"
import React from "react";
import {Link} from 'react-router-dom';
import SocialShare from "./SocialShare";
//Composant permettant l'affichage individuelle de chaque jeux

function GameCard(props) {
    const iconSize = 30;
    return (
        <div key={props.index} className="gameZone">
            <h2>{props.title}</h2>
            <img srcSet={props.thumbnail} alt={props.title} />
            <p>Dev : {props.developer}</p>
            <p>Publisher : {props.publisher}</p>
            <p>Genre : {props.genre}</p>
            <p>Release : {props.release_date}</p>
            <p>Platform : {props.platform}</p>
            <p>Description : </p>
            <p>{props.short_description}</p>
            <div className={"linkZone"}>
                <p><button onClick={()=>window.open(props.game_url)}>Official Website</button></p>
                <Link to={`/games/${props.id}`}><button>More</button></Link>
            </div>
            <div className="socialMedia">
                <SocialShare id={props.id} title={props.title}/>
            </div>
        </div>
    );
}

export default GameCard;
