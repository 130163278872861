import "../style/Quiz.css"
import React from "react";
import GameCard from "./GameCard";
import NavBar from "./NavBar";

function Quiz(props) {
    const [num,setNum] = React.useState(0)
    const [responseList,setResponseList] = React.useState([])
    let numResult = 0;
    const question = [
        {que:"Feet on the ground or in imaginary worlds?",repchoice:[["On the ground",""],["Imaginary worlds", "fantasy"]]},
        {que:"what type of graphics do you prefer",repchoice:[["Anime","anime"],["Realist",""]]},
        {que:"Thinking or acting now ?",repchoice:[["Thinking","strategy"],["Acting now",""]]},
        {que:"Fighting other players or AI ?",repchoice:[["Players","pvp"],["AI","pve"]]},
        {que:"END",repchoice:[]}
    ];

    function switchQuestion (){
        if (num < question.length-1) setNum(num + 1)
    }

    function response(value) {
        const updatedResponses = [...responseList, value];
        setResponseList(updatedResponses)
        switchQuestion()
    }

    function goBack(){
        setNum(num-1)
        setResponseList(responseList.slice(0,-1))
    }
    // {numResult=0}
    return (
        <div>
            <NavBar/>
            <div key={props.index} className="quiz">
            {question[num].que &&
            <div>
                <div className="answer">
                    <h3>Question</h3>
                    {/* Selecteur de question par rapport à la liste. */}
                    <p>{question[num].que}</p>
                </div>
                <div className="response">
                    <h3>Response</h3>
                    {/* Selecteur de réponse par rapport à la liste. */}
                    {question[num].repchoice.map&&question[num].repchoice.map((val,index)=>
                        <button onClick={()=>response(val[1])} key={"repmap" + index} className={"btnRep"}>{val[0]}</button>
                    )}
                </div>                
            </div>
            }
            {num !== 0  ? <button onClick={goBack} className={"btnBack"}>Back</button>:""}
            <div className="ApiViewer">
                <ul>
                {props.val && props.val
                // Les différents filtres en fonction de la réponse
                .filter((e) => e.short_description.includes((responseList[0] ? responseList[0]:"")))
                .filter((e) => e.short_description.includes(responseList[1]?responseList[1]:""))
                .filter((e) => e.short_description.includes(responseList[2]?responseList[2]:""))
                .filter((e) => e.short_description.includes(responseList[3]?responseList[3]:""))
                .map((val,index)=>{
                    {numResult++}
                    return (
                    <div key={"gc"+index}> 
                        <GameCard 
                        {...val}
                        
                        />
                    </div>
                    )
                }
                )}
                </ul>
                {/* verifie qu'il reste des resultats après la question 1 sinon affiche un message */}
                {num>=1 && numResult === 0? <p>No result, go back.</p>:""}
            </div>
            </div>
        </div>
    );
}

export default Quiz;
