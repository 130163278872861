import React from 'react';
import logo from "../assets/logo03.png";
import { Link } from 'react-router-dom';
import '../style/Header.css';


function Header () {
    return(
    <div className="App-header">
        <Link to="/"><img src={logo} className="logo" alt="Manette de jeu vidéo dans un portefeuille ouvert"/></Link>
        <p>Free Gamers Corner</p>
        
    </div>
    );
}


export default Header;
