import React,{useState,useEffect} from "react";
import { useParams,useNavigate } from 'react-router-dom';
import "../style/FullGameCard.css"
import axios from "axios";
import SocialShare from "./SocialShare";

//Composant permettant l'affichage individuelle de chaque jeux
function FullGameCard(props) {
    let { myid } = useParams();
    const [apiData, setApiData] = useState([])
    const [image, setImage] = useState()
    const [imageB, setImageB] = useState(false)
    const navigate = useNavigate();
    const apiKey = process.env.REACT_APP_API_KEY;

    //Lecture de l'api spécifique pour avoir plus de détail
    const options = {
        method: 'GET',
        url: 'https://free-to-play-games-database.p.rapidapi.com/api/game',
        params: {id: myid},
        headers: {
          'X-RapidAPI-Key': apiKey,
          'X-RapidAPI-Host': 'free-to-play-games-database.p.rapidapi.com'
        }
    };
    
    function switchImage(img){
        setImage(img)
    }


    useEffect(() => {
        axios(options)
        .then(data => setApiData(data.data))
        //gestion des erreurs
        .catch(error => console.warn('Authorization failed : ' + error.message));
    },[])

      //Si l'api est chargé, ça continue.
    if (apiData.length !== 0){
    return (
        <div key={apiData.index} className="fullGameZone">
            <h1>{apiData.title}</h1>
            <img srcSet={!image ? apiData.thumbnail: image} alt={apiData.title} key={"mainImageGame"}/>
            <div className="screen">
            {image? <img src={image ? apiData.thumbnail: ""} alt={apiData.title} key={"mainImageGame"} onClick={()=>setImage()}/>:""}
                {apiData.screenshots.map((scr,i)=>(
                    <img src={apiData.screenshots[i].image} alt={apiData.screenshots[i].id} key={"image" +apiData.screenshots[i].id} onClick={()=>switchImage(apiData.screenshots[i].image)}/>
                ))}
            </div>
            <div className="info">
                <div><h3>Dev : </h3><p>{apiData.developer}</p></div>
                <div><h3>Publisher : </h3><p>{apiData.publisher}</p></div>
                <div><h3>Genre : </h3><p>{apiData.genre}</p></div>
                <div><h4>Release : </h4><p>{apiData.release_date}</p></div>
                <div><h4>Platform : </h4><p>{apiData.platform}</p></div>
                <div><h4>Status : </h4><p>{apiData.status}</p></div>
            </div>
            <h3>Full Description : </h3>
            <p>{apiData.description}</p>
            {apiData.minimum_system_requirements && apiData.minimum_system_requirements.graphics?
            <div className="config">
                <div><h3>Systeme required : </h3></div>
                <div><h4>Graphics: </h4><p>{apiData.minimum_system_requirements.graphics}</p></div>
                <div><h4>Memory: </h4><p>{apiData.minimum_system_requirements.memory}</p></div>
                <div><h4>OS: </h4><p>{apiData.minimum_system_requirements.os}</p></div>
                <div><h4>Processor: </h4><p>{apiData.minimum_system_requirements.processor}</p></div>
                <div><h4>Storage: </h4><p>{apiData.minimum_system_requirements.storage}</p></div>
            </div> 
            :""}
            <button onClick={()=>navigate(-1)}>BACK</button>
            <SocialShare id={apiData.id} title={apiData.title}/>
        </div>
    )
}
}
export default FullGameCard;