import "../style/PresentUs.css"

function PresentUs(){
    return (
    <div className="present-us">
        
        <p className="hero-section">
            Noob, hardcore gamer or casual : don't know what game to play ?
        </p>
        <p className="hero-section">
            Even worse, you're broke right now ! Good news, we can help.
        </p>
        <p className="hero-section">
            So press start!
        </p>
    </div>

    );
}

export default PresentUs;