import React, {useState} from "react";
import "../style/simpleSearch.css";
import searchIcon from "../assets/search.png";
import cancelIcon from "../assets/cancel_FILL1_wght400_GRAD0_opsz24.svg";
import ApiViewer from "./ApiViewer";

const SimpleSearch = (props) => {
    
    // state to update input search written by user
    const [userSearch, setUserSearch] = useState("");
    // state with boolean to manage visibility of clearIcon with ternary condition
    const [clearIcon, setClearIcon] = useState(false);

    // manage events when inputValue has changed
    const handleChange = (e) => {
        e.preventDefault();
        //set userSearch value
        setUserSearch(e.target.value);
        //show clearIcon when inputValue != ""
        setClearIcon(true);
    }

    //  what happend when user click on clearIcon
    const clearSearch = () => {
        setClearIcon(false);
        setUserSearch("");
    }

    // l'affichage du composant avec un placeholder, et la fonction onChange pour mettre à jour le state
    return (
        <>
            <div className="search-bar">
                <img src={searchIcon} className="search-icon" alt="search glass icon" />

                <input 
                className="input-search" 
                type="text" 
                placeholder="Search a game" 
                value={userSearch} 
                onChange={handleChange}
                />

                {clearIcon && userSearch ? <img src={cancelIcon} onClick={clearSearch} className="cancel-icon" alt="icon to cancel text" /> : "" }
            </div>
            <ApiViewer userSearch={userSearch} val={props.val}/>
        
        </>
    )
}

export default SimpleSearch;