import "../style/Glossary.css";
import NavBar from "./NavBar";

function Glossary () {
    return ( 

        <div>
            <NavBar/>
                <div className="glossary">
                    <div className="index-wrapper" id="top">

                    <a href="">
                        <h1>A</h1>
                    </a><a href="#a">
                        <h1>B</h1>
                    </a><a href="#b">
                        <h1>C</h1>
                    </a><a href="#c">
                        <h1>D</h1>
                    </a><a href="#d">
                        <h1>E</h1>
                    </a><a href="#e">
                        <h1>F</h1>
                    </a><a href="#f">
                        <h1>G</h1>
                    </a><a href="#g">
                        <h1>H</h1>
                    </a><a href="#h">
                        <h1>I</h1>
                    </a><a href="#i">
                        <h1>K</h1>
                    </a><a href="#k">
                        <h1>L</h1>
                    </a><a href="#l">
                        <h1>M</h1>
                    </a><a href="#m">
                        <h1>N</h1>
                    </a><a href="#n">
                        <h1>O</h1>
                    </a><a href="#o">
                        <h1>P</h1>
                    </a><a href="#p">
                        <h1>Q</h1>
                    </a><a href="#q">
                        <h1>R</h1>
                    </a><a href="#r">
                        <h1>S</h1>
                    </a><a href="#s">
                        <h1>T</h1>
                    </a><a href="#t">
                        <h1>U</h1>
                    </a><a href="#u">
                        <h1>V</h1>
                    </a><a href="#v">
                        <h1>W</h1>
                    </a>
                    </div>
                    <div className="glossary-content">

                    <h3>A</h3>
                    <p>
                        <span className="title">Achievement : </span>an event that rewards the specific achievement of a player, defined in advance by the development team.
                    </p>
                    <p>
                        <span className="title">AFK : </span>Away From Keyboard : not to be in front of a PC or console.
                    </p>
                    <p>
                        <span className="title">Aggro : </span>attack of a player by several of his opponents, human or controlled by the AI.</p>
                    <p >
                        <span className="title">Avatar : </span>character embodied by the player.</p>
                    <h3 id="#w">B <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p >
                        <span className="title">Boss : </span>major enemy that you usually face at the end of the level (final boss at the end of the game). Exemples : Bowser (Mario), Ganondorf (Zelda) ou encore Sephiroth (Final Fantasy VII).</p>
                    <p >
                        <span className="title">Bot : </span>character embodied by the game's artificial intelligence (short for "robot").</p>
                    <h3 id="c">C <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p >
                        <span className="title">Casual Gamer : </span>casual gamer, who is not passionate about video games. Initially rather pejorative, this expression is now rather neutral and is simply used to differentiate the potential targets of a game.</p>
                    <p >
                        <span className="title">Cheat : </span>software or file modification in a game that offers an advantage. This practice is often fun in solo games (infinite lives, all weapons etc.), but is particularly problematic in online games. Examples: wallhack (see through walls), speedhack (run faster), aimbot (automatic aim).</p>
                    <p >
                        <span className="title">Checkpoint : </span>A checkpoint is a place in a game where progress is automatically recorded as the player passes through.</p>
                    <p >
                        <span className="title">Clipping : </span>development technique aimed at relieving the game engine by the total or partial disappearance of elements that the player is not supposed to see on the screen. 
                        Sometimes, the machine takes some time to recalculate the elements visible or not by the player, which can cause "clipping effects", that is to say the appearance of graphic elements of inferior quality (or of a black wall) or their sudden disappearance. Example: clipping in Assassin's Creed and Cyberpunk 2077
                    </p>
                    <p >
                        <span className="title">Combo : </span>A sequence of moves without the opponent having the possibility to protect himself or to retaliate. Example: Ryu's combos in Street Fighter V and Batman's in Arkham Night.</p>
                    <p >
                        <span className="title">Cooldown : </span>waiting time after the use of a spell or a power before being able to use it again.</p>
                    <h3 id="d">D <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Desync : </span>desynchronization is, in an online game, the discrepancy between what the player sees on his screen and the action interpreted by the server, which causes an imbalance in the action and an unpleasant feeling of injustice. 
                        Example: in this excerpt from Apex Legends, you can clearly hear the impacts and the sight seems to be on the target, but no damage is recorded by the game</p>
                    <p>
                        <span className="title">DPS : </span>Damage Per Second inflicted by a character. Can also refer to the character in a group responsible for doing damage to the opponent (the healer heals, the tank defends and the wizard casts spells).</p>
                    <p>
                        <span className="title">Drop : </span>loot dropped by a monster.</p>
                    <h3 id="e">E <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Esport : </span>E-sports includes all competitions held on video games. Examples: League of Legends, Counter Strike or FIFA are games where the eSport dimension is very developed.</p>
                    <h3 id="f">F <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Farmer : </span>do the same thing several times in order to advance your character.</p>
                    <p>
                        <span className="title">FFA : </span>Free For All mode welcomes all players of an online game.</p>
                    <p>
                        <span className="title">FPS : </span>First Person Shooter. A game played from a first-person perspective, where the avatar's body does not appear on the screen. 
                        Examples: Doom, Quake or Call of Duty are FPS.</p>
                    <p>
                        <span className="title">Frag : </span>number of opponents killed in a game.</p>
                    <p>
                        <span className="title">Freeze : </span>frozen" screen because of a blocking bug or a hardware problem. The freeze can be permanent (crash) or temporary (the screen freezes for a few milliseconds). Example : more or less long freezes on Call of Duty Warzone</p>
                    <h3 id="g">G <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Game Design : </span>The creative process that leads to the theoretical design of a video game (rules, action, narrative). It is recorded in a Game Design Document that constitutes the game's bible and will guide its development from start to finish. 
                        The game designer (see job description) is in charge of this responsibility. Example: the Game Design Document for BioShock, released in 2007.</p>
                    <p>
                        <span className="title">Gameplay : </span>what you do in a video game and how you do it. For example: moving forward, backward, jumping and running are the basic gameplay of Mario, which then evolves according to his costumes.</p>
                    <p>
                        <span className="title">Procedural generation: </span>refers to generating items randomly under predefined conditions. 
                        Gives a unique look to each level/part while keeping the consistency of the game world. Example: No Man's Sky generates its environments and planets procedurally.</p>
                    <h3 id="h">H <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Hardcore Gamer : </span>Player don't stop playing</p>
                    <p>
                        <span className="title">Hitbox : </span>collision mask of an element representing its interaction zone (with the scenery, projectiles, other characters). 
                        Especially important in fighting games, shoot'em ups, action games and racing games. Example: Ryu's hitbox in Street Fighter IV.</p>
                    <p>
                        <span className="title">Hitmarker : </span>A visual cue indicating a collision, often between a projectile and its target. Allows, for example in FPS, to know when a bullet you shoot 
                        reaches its target or not. Example : compilation of hitmakers (without frag) on Fortnite.  </p>
                    <p>
                        <span className="title">HP : </span>Health Points means life points and represents the health of a character.</p>
                    <p>
                        <span className="title">HUD : </span>Head Up Display is the information displayed on the screen. It is the information displayed on the screen (health points, experience, ammunition ...). 
                        Example: the loaded HUD of Horizon: Zero Dawn and the very bare HUD of Dead Space (everything is displayed on the character and his weapon).</p>
                    <h3 id="i">I <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    

                    <p>
                        <span className="title">IA : </span>acronym for Artificial Intelligence: behavior of a character governed by computer functions in order to replicate a logical behavior.</p>
                    <p>
                        <span className="title">Input Lag : </span>lag between the action performed on the controller and its actual execution on the screen.</p>
                    <h3 id="k">K <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Kick : </span>action of excluding someone (from a game, from a discussion...).</p>
                    <h3 id="l">L <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Ladder : </span>A competition that is spread out over a given period of time, more or less long depending on the game and the number of participants.</p>
                    <p>
                        <span className="title">Lag : </span>too long response time of a server, due to its quality or that of the user's Internet connection.</p>
                    <p>
                        <span className="title">Level Up : </span>gain a level (weapon, character, equipment).</p>
                    <p>
                        <span className="title">Loot : </span>loot that you pick up in a game, in the scenery or on an enemy's body.</p>
                    <p>
                        <span className="title">Lootbox : </span>A lootbox that works on a lottery system: the player does not know what item he will get by opening the box. 
                        A financial consideration is sometimes required (see: microtransaction) to obtain a lootbox or the right to open it. Often contains a cosmetic item or a character. 
                        Example: video of lootbox opening in Counter Strike: Global Offensive.</p>
                    <h3 id="m">M <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Main : </span>Anglicism that designates the character that the player prefers to embody or that he/she masters the best.</p>
                    <p>
                        <span className="title">Mob : </span>Basic enemy of a game, often easy to eliminate.</p>
                    <p>
                        <span className="title">MOBA : </span>acronym for Multiplayer Online Battle Arena. A type of competitive game in which two teams fight each other 
                        on a symmetrical map with a simple construction (top lane, middle lane, bottom lane). Examples: DOTA 2, League of Legends.</p>
                    <h3 id="n">N <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Nerf / Buff : </span>weakening or improving a skill, equipment, class or weapon in order to balance a game.</p>
                    <p>
                        <span className="title">Noob : </span>Beginner player (also called bambi in Fortnite).</p>
                    <p>
                        <span className="title">NPC / PNJ : </span>acronym for Non Playing Character. Term that characterizes all characters in a game that are not played by a human player.</p>
                    <h3 id="o">O <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">OP : </span>acronym for Overpowered. Said of a character, equipment or weapon that is too powerful compared to other similar elements.</p>
                    <p>
                        <span className="title">Open World : </span>Anglicism translated by Open World in French. It is a game genre characterized by a vast space available for action. 
                        Examples: Assassin's Creed, Grand Theft Auto or Just Cause are open world games.</p>
                    <h3 id="p">P <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Pattern : </span>a character's pattern of actions that is repeated over and over again. Knowing a pattern allows you to anticipate the movements and attacks of an opponent 
                        especially a boss.</p>
                    <p>
                        <span className="title">Pexer : </span>accumulate experience points in order to upgrade your character/vessel/clan.</p>
                    <p>
                        <span className="title">Ping : </span>server response time, in milliseconds. Depending on the internet connection of the client (the player). The lower it is, the better.</p>
                    <p>
                        <span className="title">PvE and PvP : </span>acronym for Player vs Environment and Player vs Player. An area or mode of play where the player 
                        faces opponents managed by artificial intelligence (PvE) or other human players (PvP).</p>
                    <h3 id="q">Q <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">QTE : </span>acronym for Quick Time Event. Designates a contextual action to be performed in a limited time, by pressing the right key at the right time. 
                        Example: all the QTE in Marvel's Spider Man.</p>
                    <h3 id="r">R <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Ragequit : </span>The act of abandoning a game in progress, often because one loses or because of unpleasant behavior.</p>
                    <p>
                        <span className="title">Roguelike/Roguelite : </span>kind of game characterized by the random generation of some of its elements and the permanent death implying to restart the game at each 
                        failure. Examples: Hades, Dead Cells and Darkest Dungeon are roguelites.</p>
                    <p>
                        <span className="title">RPG : </span>acronym for Role Playing Game. In video games, two main categories exist: Western RPGs (Baldur's Gate, Mass Effect)
                        and Japanese RPGs (Final Fantasy, Dragon Quest).</p>
                    <p>
                        <span className="title">RTS : </span>Real Time Strategy is a genre that requires the player to have a tactical and strategic approach to events, in addition to a good speed of execution.
                        speed of execution. Examples: Starcraft II, Age of Empires II and Warcraft III are RTS.
                    </p>
                    
                    <p>
                        <span className="title">Rush : </span>combat tactic aiming at rushing at the opponent by using the surprise effect.</p>
                    <h3 id="s">S <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Sandbox: </span>A type of game based on a non-linear and emergent gameplay that calls upon the creativity of the players, whether the goal of the game is defined or not. 
                        Examples: Minecraft or Eve Online are sandbox games.</p>
                    <p>
                        <span className="title">Serious Game : </span>A type of game based on a non-linear and emergent gameplay that calls upon the creativity of the players, whether the goal of the game is defined or not. 
                        Examples: Minecraft or Eve Online are sandbox games.</p>
                    <p>
                        <span className="title">Skill : </span>refers to talent. Used to gauge a player's level in a game or to define a character's special ability.</p>
                    <p>
                        <span className="title">Soluce : </span>content aimed at helping the player to complete a game by detailing the steps to follow, step by step, to progress. Example: the Assassin's Creed Valhalla solution</p>
                    <p>
                        <span className="title">Spawn : </span>The place where the player or his opponents appear in a game.</p>
                    <p>
                        <span className="title">Speedrun : </span>practice which consists in finishing a game as quickly as possible, by exploiting its technical flaws or not. 
                        Example: a speedrun of Celeste with a commentary in French.</p>
                    <p>
                        <span className="title">Straffe : </span>action of moving sideways (the equivalent of chasing steps). Widely used in first person games.</p>
                    <p>
                        <span className="title">Stuff : </span>refers to all items worn by a character in a game.</p>
                    <h3 id="t">T <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Trigger/Tilt : </span>refers to the fact of losing one's temper. A subject, an action or a behavior can provoke us and put us in a state of 
                        and plunge us into a state of emotional instability and frustration.</p>
                    <h3 id="u">U <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">UI : </span>acronym for User Interface. Designates the interaction device allowing a user to manipulate a software. 
                        The menu of a game is for example part of it. Example: the interface and menus of Persona 5, a model of its kind.</p>
                    <h3 id="v">V <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Voxel : </span>contraction of Volumetric Pixel which corresponds to the representation of a pixel in space. Example: an explanation of the voxel.</p>
                    <h3 id="w">W <button className="back-top"><a href="#top"> ⇧ TOP ⇧</a></button></h3>
                    <p>
                        <span className="title">Wipe : </span>reset a game server, causing the loss of all data stored on that server.</p>
                </div>
            </div>
        </div>
    )
};

export default Glossary;