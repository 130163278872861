import logo03 from "../assets/startbutton02.png";
import logo04 from "../assets/StopButton.png";
import logo10 from "../assets/Quizbutton.png";
import logo11 from "../assets/startbutton11.png";
import logo12 from "../assets/startbutton12.png";
import logo13 from "../assets/startbutton13.png";
import logo14 from "../assets/startbutton14.png";
import "../style/RadialMenu.css"
import {Link} from 'react-router-dom';
import React from "react";
import ApiAccess from "./ApiAccess";

// Import the circular menu
import {
  CircleMenu,
  CircleMenuItem,
  TooltipPlacement,
} from "react-circular-menu";

export const RadialMenu = (props) => {
  const [menu,setMenu] = React.useState(false)

  return (
    <div>
      <ApiAccess compo="search" className="test"/>
    <CircleMenu
      startAngle={-18} /* position objets */
      rotationAngle={360} /*effet de rotation */
      itemSize={1} /* taille objets */
      radius={9} /* rayons */
      /**
       * rotationAngleInclusive (default true)
       * Whether to include the ending angle in rotation because an
       * item at 360deg is the same as an item at 0deg if inclusive.
       * Leave this prop for angles other than 360deg unless otherwise desired.
       */
      rotationAngleInclusive={false}
      menuToggleElement={<img className={menu?"myRadial2":"myRadial"}src={menu?logo04:logo03} alt="" width={menu?"100px":"150px"}/>}
      onMenuToggle={(menuActive) => setMenu(menuActive)}
    >
      
      <CircleMenuItem tooltip="Game Index" tooltipPlacement={TooltipPlacement.Right}>
        <Link to="/indexgames">
          <img src={logo12} alt="" width="100px"/>
        </Link>
      </CircleMenuItem>

      <CircleMenuItem tooltip="Random" tooltipPlacement={TooltipPlacement.Right}
      >
        <Link to="/random">
          <img src={logo11} alt="" width="100px"/>
        </Link>
      </CircleMenuItem>

      
      <CircleMenuItem tooltip="Quiz" tooltipPlacement={TooltipPlacement.Right}>
        <Link to="/quiz">
          <img src={logo10} alt="" width="100px"/>
        </Link>
      </CircleMenuItem>

      <CircleMenuItem tooltip="Search" tooltipPlacement={TooltipPlacement.Right}>
        <Link to="/advanced-search">
          <img src={logo13} alt="" width="100px"/>
        </Link>
      </CircleMenuItem>

      <CircleMenuItem tooltip="Glossary" tooltipPlacement={TooltipPlacement.Right}>
        <Link to="/glossary">
          <img src={logo14} alt="" width="100px"/>
        </Link>
      </CircleMenuItem>
    </CircleMenu>
    </div>
  );
};