import "../style/SocialShare.css"
import {
    EmailShareButton,
    FacebookShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";
  import { FacebookIcon, TwitterIcon,EmailIcon,RedditIcon,WhatsappIcon } from "react-share";
//Composant permettant l'affichage individuelle de chaque jeux
function SocialShare({id,title}) {
    const iconSize = 30;
    return (
            <div className="socialMedia">
                <EmailShareButton 
                    body={"http://127.0.0.1:3000/games/"+ id}
                    subject={title}
                >
                    <EmailIcon size={iconSize}/>
                </EmailShareButton>
                <FacebookShareButton
                    url={"http://127.0.0.1:3000/games/"+ id}
                    title={title}
                    hashtag={`#free2play`}
                    description={"game"}
                    // className="Demo__some-network__share-button"
                >
                    <FacebookIcon size={iconSize}/>
                </FacebookShareButton>
                <TwitterShareButton
                    title={title}
                    url={"http://127.0.0.1:3000/games/"+ id}
                    hashtags={["free2play", title ? title.split(" ").join("_"):""]}
                >
                    <TwitterIcon size={iconSize}/>
                </TwitterShareButton>
                <WhatsappShareButton
                title={title}
                url={"http://127.0.0.1:3000/games/"+ id}
                >
                    <WhatsappIcon size={iconSize}/>
                </WhatsappShareButton>
                <RedditShareButton
                title={title}
                url={"http://127.0.0.1:3000/games/"+ id}
                >
                    <RedditIcon size={iconSize}/>   
                </RedditShareButton>
                
            </div>
    );
}

export default SocialShare;
