import "../style/SelectedGames.css"
import GameCard from "./GameCard";
import React, { useState, useEffect } from "react";
import axios from "axios";

//Composant d'affichage de la liste de l'api
function SelectedGames(props) {

    const [lastReleases, setLastReleases] = useState();

    const apiKey = process.env.REACT_APP_API_KEY;

    //Definit les options pour le lien de l'api avec les clefs API
    const options = {
        method: 'GET',
        url: 'https://free-to-play-games-database.p.rapidapi.com/api/games',
        params: {'sort-by': 'release-date'},
        headers: {
          'X-RapidAPI-Key': apiKey,
          'X-RapidAPI-Host': 'free-to-play-games-database.p.rapidapi.com'
        }
      };

    useEffect(() => {
        axios(options)
        .then((response) => response.data)
        .then((data) => setLastReleases(data)
        
       )
        },[]);


    return (
        <div className="SelectedGames">
         <h2>Last releases</h2>
            <ul>
                {/* Boucle via map sur la liste pour passer les elements à l'enfant GameSelect */}
                {lastReleases && lastReleases
                .map((valu, index) => (
                    <GameCard 
                    className="selected"
                    {...valu}
                    key={index}
                    />
                ))
                .slice(0,2)}
            </ul>
        </div>
    );
}

export default SelectedGames;