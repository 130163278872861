import React, {useState} from 'react';
import GameCard from './GameCard';
import '../style/AdvancedSearch.css';
import NavBar from './NavBar';

const AdvancedSearch = ({val}) => {
  
  // get specific data from API and keep into an array
  const platform = [];
  // get specific data from API and keep into an array
  const genre = [];
  // checking when an input is checked or not, and uptade this value
  const [platformSelected, setPlatformSelected] = useState([]);
  const [genreSelected, setGenreSelected] = useState([]);
  // checking if a dropdown menu is clicked and showed or not
  const [dropdownPlatform, setDropdownPlatform] = useState(false);
  const [dropdownGenre, setDropdownGenre] = useState(false);

  // mapping data to update arrays
  val && val.map((element) => {
      if(!platform.includes(element.platform)){
        platform.push(element.platform)
      }
    })
  
  val && val.map((element) => {
      if(!genre.includes(element.genre)){
        genre.push(element.genre)
      }
    })
  
  // some functions adding checked values in arrays, and manage actions to find corresponding games in data
  const handleCheckedPlatform = (e) => {
    
    if (e.target.checked){
    const updatedPlatformSelected = [...platformSelected,e.target.value];
    setPlatformSelected(updatedPlatformSelected)
    }else{
      const remArray = platformSelected.filter((f)=> f != e.target.value);
      setPlatformSelected(remArray)
    }
  }
  const handleCheckedGenre = (e) => {
    
    if (e.target.checked){
    const updatedGenreSelected = [...genreSelected,e.target.value];
    setGenreSelected(updatedGenreSelected)
    }else{
      const remArray = genreSelected.filter((f)=> f != e.target.value);
      setGenreSelected(remArray)
    }
  }

  const handleClickPlatform = () => {
    setDropdownPlatform((prev) => !prev)
  };

  const handleClickGenre = () => {
    setDropdownGenre((prev) => !prev)
  }

  return (
    <>
      <NavBar/>
      <div className="dd-wrapper">

        <div className={ !dropdownPlatform ? "checkbox-dropdown-platform" : "is-active-platform checkbox-dropdown-platform"} onClick={handleClickPlatform}>
          <p>Choose platform(s)</p>

          <ul className="checkbox-dropdown-list">
          {platform.map((item, index) => 
            <li key={"check" + index}>
              <label>
                <input 
                id={index}
                type="checkbox" 
                value={item} 
                name="platform"
                onChange={handleCheckedPlatform} 
                />{item}
              </label>
            </li>         
            ).slice(0,2)}      
          </ul>
        </div> 

        <div className="split-content">
        </div> 

        <div className={ !dropdownGenre ? "checkbox-dropdown-genre" : "is-active-genre checkbox-dropdown-genre"} onClick={handleClickGenre}>
          <p>Choose genre(s)</p>

          <ul className="checkbox-dropdown-list">
          {genre.map((item, index) => 
            <li key={"check2" + index}>
              <label>
                <input 
                id={index}
                type="checkbox" 
                value={item} 
                name="genre"
                onChange={handleCheckedGenre} 
                />{item}
              </label>
            </li>         
            )}      
          </ul>
        </div>   
      </div>
      <div className="ApiViewer">
        <ul>
    {val && val
      .filter((e) => e.platform.includes(platformSelected.length ===0 || platformSelected.length ===2? "":platformSelected[0]))
      .filter((e)=>{
        if (genreSelected.length === 0) return true;
        for(let i=0;i < genreSelected.length;i++){
          if(genreSelected[i] === e.genre) {
            return true;
          }
        }
      })
      .map((item, index) => (
        <GameCard {...item} key={index}/>
        ))
      }
      </ul>
      </div>
    </>
  )
  
}

export default AdvancedSearch
