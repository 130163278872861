import React from "react";
import "../style/ApiViewer.css"
import GameCard from "./GameCard";
import Footer from "./Footer"

//Composant d'affichage de la liste de l'api
function ApiViewer(props) {
    return (
        <div className="ApiViewer">
            <ul>
                {/* Boucle via map sur la liste pour passer les éléments à l'enfant GameCard */}
                {props.val && props.val
                .filter((e) => e.title.toLowerCase().includes(props.userSearch.toLowerCase())
                )
                .map((valu, index) => ( props.userSearch.length >= 3 ?
                    <GameCard 
                    {...valu}
                    key={index}
                    /> : ""
                ))}
            </ul>
            <Footer/>
        </div>
    );
}

export default ApiViewer;
