import React, {useState, useEffect} from "react";
import { useParams,useNavigate } from 'react-router-dom';
import "../style/RandomGame.css"
import GameCard from "./GameCard";
import mysterie from "../assets/mysterie.png"
import NavBar from "./NavBar";

//Composant permettant l'affichage individuel de chaque jeu
function RandomGame(props) {
  let { myrandom } = useParams();
  let [stat,setStat] = useState()
  const navigate = useNavigate();
  let myRand 
  if (myrandom) myRand = myrandom.split(",");
  const myRandomList = [];
  useEffect(() => {
    if (myRandomList.length !== 0){
        navigate('/random/' + myRandomList.join(","))   
       }
    },[stat])

  function handleRandom() {
    setTimeout(() => {
      setStat(myRandomList.join(","))
    });
  }

  //Si le props n'est pas vide, le code continue
  if (props.val.length !== 0){
    //Fonction générant les chiffres aléatoires.
    //Vérifie qu'il n'y a pas de doublon
    function getRandom(numOfRandom){
      let temp = numOfRandom
      let max = props.val.length
      //sécurité pour éviter une boucle infinie
      if (numOfRandom>max) return console.warn("Warn you try to generate more than index")
      //générer un chiffre aléatoire
      let random = Math.floor(Math.random() * max)
      //vérification que le nombre généré n'est pas dans la liste
      if (myRandomList.includes(random) !== true) myRandomList.push(random);
      //permet de boucler sur la fonction tant que le nombre attendu n'est pas atteint
      if (myRandomList.length !== numOfRandom) getRandom(temp)
    }
    //definie le nombre de jeux tiré au sort ou verifie qu'il ne sont pas en parametre
    if(myrandom){
      myRand.forEach(val =>{
        //Verifie que la valeur est valide ou en dessous de la longueur de l'api.
        if(val <= props.val.length){
          myRandomList.push(val)
        }else{
          console.warn("you try but sorry no crash")
        }
        
      });
      //si rien en parametre, en genere 3
    }else{
      getRandom(3)
      myRand=myRandomList.join(",")
      //change de page après la génération
    }
    handleRandom()
    return (
      <div>
        <NavBar/>
        <div className="randomGame">
        {myRandomList.map((randoms)=>(
          <div key={props.val[randoms].title + randoms} className="card">
            <div className="face front">
              <img src={mysterie} alt="" className="mysterieImg"/>  
            </div>
            <div className="face back">
              <GameCard 
                {...props.val[randoms]}
                key={props.val[randoms].title + randoms}
              />
            </div>            
          </div>
        ))}
        </div>
      </div>
    );
}
}

export default RandomGame;





