import './style/App.css';
import Header from './components/Header';
import ApiAccess from './components/ApiAccess';
import { RadialMenu } from './components/RadialMenu';
import PresentUs from './components/PresentUs';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FullGameCard from './components/FullGameCard';
import Footer from './components/Footer';
import Glossary from './components/Glossary';



function App() {
  return (
    <Router>
      <div className="App">
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
        </style>
        <Header/>      
          <div className="zoneRoute">
            <Routes>
              <Route path="/" element={<div><RadialMenu /><ApiAccess compo="selected"/><PresentUs /></div>}/>
              <Route path='/advanced-search' element={<ApiAccess compo="advanced-search"/>} />
              <Route path='/search/:mytype' element={<ApiAccess compo="search"/>} />
              <Route path='/random' element={<ApiAccess compo="random"/>} />
              <Route path='/indexgames' element={<ApiAccess compo="index"/>} />
              <Route path='/indexgames/:letter' element={<ApiAccess compo="index"/>} />
              <Route path='/random/:myrandom' element={<ApiAccess compo="random"/>} />
              <Route path='/glossary' element={<Glossary />} />
              <Route path='/quiz' element={<ApiAccess compo="quiz"/>} />
              <Route path='/games/:myid' element={<FullGameCard/>} />
            </Routes>
          </div>
        <Footer/>
      </div>
    </Router>
  )
}

export default App;

